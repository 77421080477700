import { useHandler } from "@redotech/react-util/hook";
import jwtDecode from "jwt-decode";
import {
  ReactNode,
  createContext,
  memo,
  useContext,
  useEffect,
  useState,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { CustomerContext } from "../contexts/CustomerContext";
import { UserContext } from "./user";

export interface Auth {
  teamId: string;
  userEmail: string;
  token: string;
  expiration: Temporal.Instant;
}

export const AuthContext = createContext<Auth | undefined>(undefined);

export interface SetAuth {
  (token: string | undefined): void;
}

export const SetAuthContext = createContext<SetAuth | undefined>(undefined);

const AUTH_TOKEN_KEY = "redo.portal_auth_token";

/**
 * Redirect to /login if not logged in
 */
export const AuthGuard = memo(function AuthGuard({
  children,
}: {
  children: ReactNode | ReactNode[];
}) {
  const auth = useContext(AuthContext);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!auth) {
      navigate(`/returns-portal/login?next=${location.pathname}`);
    }
  }, [auth]);

  if (!auth) {
    return null;
  }

  return <>{children}</>;
});

/**
 * Redirect to /returns-portal if we don't have a customer id
 * We don't want a user without a customer id getting into the customer portal right now
 * If we have a customer id, wait to fully load the customer context before rendering children
 */
export const CustomerAccountAuthGuard = memo(function CustomerAccountAuthGuard({
  children,
}: {
  children: ReactNode | ReactNode[];
}) {
  const customerContext = useContext(CustomerContext);
  const userAuth = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (!!userAuth && !userAuth.customerId) {
      navigate(`/returns-portal`);
    }
  }, [userAuth?.customerId]);

  if (!customerContext) {
    return null;
  }

  return <>{children}</>;
});

function tokenToAuth(token: string): Auth {
  const decoded: any = jwtDecode(token);
  return {
    teamId: decoded.team_id,
    userEmail: decoded.email,
    token,
    expiration: Temporal.Instant.fromEpochSeconds(decoded.exp),
  };
}

export const AuthProvider = memo(function AuthProvider({
  children,
}: {
  children: ReactNode | ReactNode[];
}) {
  const [auth, setAuth] = useState<Auth | undefined>(() => {
    const token = localStorage.getItem(AUTH_TOKEN_KEY);
    if (token === null) {
      return undefined;
    }
    return tokenToAuth(token);
  });

  useEffect(() => {
    if (!auth) {
      return undefined;
    }
    const duration = auth.expiration.since(Temporal.Now.instant());
    if (duration.sign < 0) {
      setAuth(undefined);
      localStorage.removeItem(AUTH_TOKEN_KEY);
      return undefined;
    }
    const timeout = setTimeout(
      () => setAuth(undefined),
      Math.min(2147483647, duration.total("milliseconds")),
    );
    return () => clearTimeout(timeout);
  }, [auth]);

  const setAuth_ = useHandler<SetAuth>((token) => {
    if (token === undefined) {
      localStorage.removeItem(AUTH_TOKEN_KEY);
      setAuth(undefined);
    } else {
      localStorage.setItem(AUTH_TOKEN_KEY, token);
      setAuth(tokenToAuth(token));
    }
  });
  return (
    <AuthContext.Provider value={auth}>
      <SetAuthContext.Provider value={setAuth_}>
        {children}
      </SetAuthContext.Provider>
    </AuthContext.Provider>
  );
});
