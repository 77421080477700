import { datadogLogs } from "@datadog/browser-logs";
import { composeWrappers } from "@redotech/react-util/component";
import { ThemeProvider } from "@redotech/redo-web/theme-provider";
import * as Sentry from "@sentry/react";
import { createRoot } from "react-dom/client";
import {
  Outlet,
  RouterProvider,
  createBrowserRouter,
  useRouteError,
} from "react-router-dom";
import { accountRoutes } from "./account/routes";
import { InternalRedirect, Redirect } from "./app/redirect";
import { DATADOG_CLIENT_TOKEN, SENTRY_DSN } from "./config";
import { csatRoutes } from "./csat/route";
import { CustomerWidgetWrapper } from "./customer-widget-wrapper";
import { loginRoutes } from "./login/route";
import { orderRoutes } from "./order/routes";
import { returnRoutes } from "./return/routes";
import { Root } from "./root";
import "./styles.css";
import { setFavicon } from "./utils/utils";
import { thirdPartyWarrantiesRoutes } from "./warranties/routes";

// Initialize exchange rates for currency conversion - necessary to use Money objects
// TODO @JstnMcBrd Change the passed-in method to only call getExchangeRates() if it's been over a certain amount of time,
// and otherwise pull from localStorage. Also need to find a way to save the exchange rates to localStorage.
// TODO @JstnMcBrd Re-enable once Money objects are ready
// import { ExchangeRatesManager } from "@redotech/money/exchange-rates";
// import { getExchangeRates } from "./api";
// void ExchangeRatesManager.initialize(getExchangeRates);

if (SENTRY_DSN) {
  Sentry.init({ dsn: SENTRY_DSN });
}

if (DATADOG_CLIENT_TOKEN) {
  datadogLogs.init({
    clientToken: DATADOG_CLIENT_TOKEN,
    site: "datadoghq.com",
    forwardErrorsToLogs: true,
    sessionSampleRate: 100,
  });
}

// Captures the "/widget_id/*" from the path to use as the prefix for local development
// and when loading the return portal directly from returns.getredo.com (complete exchange links)
const WIDGET_ID_PATH_PREFIX = /^(\/widget_id\/[^/]+).*/;

let basename =
  base_path && base_path !== "base_path"
    ? base_path
    : WIDGET_ID_PATH_PREFIX.test(window.location.pathname)
      ? window.location.pathname.replace(WIDGET_ID_PATH_PREFIX, "$1")
      : "/";

const queryParams = new URLSearchParams(window.location.search);
const hash = window.location.hash;
if (hash.startsWith("#/")) {
  if (basename === "/" && queryParams.has("widget_id")) {
    basename = `/widget_id/${queryParams.get("widget_id")}/`;
  }
  const newURL = new URL(
    `${basename}/returns-portal/${hash.slice(1)}`.replaceAll("//", "/"),
    window.location.origin,
  );
  queryParams.forEach((value, key) => {
    if (key !== "widget_id") {
      newURL.searchParams.append(key, value);
    }
  });
  window.location.href = newURL.toString();
}

function ErrorBoundary() {
  const error = useRouteError();
  console.error(error);
  return <div>Not Found</div>;
}

const router = createBrowserRouter(
  [
    {
      path: "/returns-portal",
      children: [
        {
          element: <Root />,
          errorElement: <ErrorBoundary />,
          children: [
            {
              index: true,
              element: <Redirect preserveQuery to="login" />,
            },
            {
              path: "csat",
              element: <Outlet />,
              children: csatRoutes,
            },
            {
              path: "login",
              element: <Outlet />,
              children: loginRoutes,
            },
            {
              element: (
                <CustomerWidgetWrapper>
                  <Outlet />
                </CustomerWidgetWrapper>
              ),
              children: [
                {
                  path: "orders",
                  element: <Outlet />,
                  children: orderRoutes,
                },
                {
                  path: "returns",
                  element: <Outlet />,
                  children: returnRoutes,
                },
                {
                  path: "warranties",
                  element: <Outlet />,
                  children: thirdPartyWarrantiesRoutes,
                },
                {
                  path: "account",
                  element: <Outlet />,
                  children: accountRoutes,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      path: "/redirect/:key",
      element: <InternalRedirect />,
    },
    {
      path: "*",
      element: <Redirect preserveQuery to="/returns-portal" />,
    },
  ],
  {
    basename,
  },
);

void setFavicon();

const ReturnApp = () => {
  return composeWrappers((inner) => <ThemeProvider>{inner}</ThemeProvider>)(
    <RouterProvider router={router} />,
  );
};

const root = createRoot(document.getElementById("root"));
root.render(<ReturnApp />);
