import { useHandler } from "@redotech/react-util/hook";
import { LoadState, useLoad } from "@redotech/react-util/load";
import { GetStoreCreditTransactionResponseBody } from "@redotech/redo-model/customer-wallet/customer-wallet-definition";
import { createContext, useContext, useState } from "react";
import {
  getStoreCreditBalance,
  getStoreCreditTransactionHistory,
  StoreCreditBalance,
} from "../api";
import { CustomerContext } from "./CustomerContext";

interface StoreCreditContextValue {
  storeCreditTransactionsLoad: LoadState<GetStoreCreditTransactionResponseBody>;
  storeCreditBalanceLoad: LoadState<StoreCreditBalance>;
  reload: () => void;
}
export const StoreCreditContext = createContext<
  StoreCreditContextValue | undefined
>(undefined);

export const StoreCreditContextProvider = ({ children }) => {
  const customer = useContext(CustomerContext);
  const [reloadStoreCreditTrigger, setReloadStoreCreditTrigger] =
    useState(Symbol());

  const storeCreditTransactionsLoad = useLoad(
    async (signal) => {
      if (customer.email) {
        const storeCreditTransactions = await getStoreCreditTransactionHistory({
          customerId: customer._id,
          signal,
        });

        return storeCreditTransactions;
      } else {
        return undefined;
      }
    },
    [customer, reloadStoreCreditTrigger],
  );

  const storeCreditBalanceLoad = useLoad(
    async (signal) => {
      if (customer.email) {
        const storeCreditBalance = await getStoreCreditBalance({
          customerId: customer._id,
          signal,
        });

        return storeCreditBalance;
      } else {
        return undefined;
      }
    },
    [customer, reloadStoreCreditTrigger],
  );

  const reloadStoreCredit = useHandler(() =>
    setReloadStoreCreditTrigger(Symbol()),
  );

  const value: StoreCreditContextValue = {
    storeCreditTransactionsLoad,
    storeCreditBalanceLoad,
    reload: reloadStoreCredit,
  };

  return (
    <StoreCreditContext.Provider value={value}>
      {children}
    </StoreCreditContext.Provider>
  );
};
