import { StyleManager } from "@redotech/web-util/web-components/style";
import { ReactNode, useLayoutEffect } from "react";
import { createPortal } from "react-dom";

export function useStyleProperty(
  node: HTMLElement,
  name: string,
  value: string,
) {
  useLayoutEffect(() => {
    const oldValue = node.style.getPropertyValue(name);
    node.style.setProperty(name, value);
    return () => node.style.setProperty(name, oldValue);
  }, [node, name, value]);
}

export function useStyleManager(
  styleManager: StyleManager,
  shadowRoot: ShadowRoot,
) {
  useLayoutEffect(
    () => styleManager.register(shadowRoot),
    [styleManager, shadowRoot],
  );
}

export function useStyleRoot(
  container: Element,
  styleManager: StyleManager,
): ShadowRoot {
  const shadowRoot =
    container.shadowRoot ?? container.attachShadow({ mode: "open" });
  useStyleManager(styleManager, shadowRoot);
  return shadowRoot;
}

export function StyleRoot({
  children,
  container,
  styleManager,
}: {
  children: ReactNode | ReactNode[];
  container: Element;
  styleManager: StyleManager;
}) {
  const shadowRoot = useStyleRoot(container, styleManager);
  return createPortal(children, shadowRoot);
}
