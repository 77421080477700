import {
  arrayJsonFormat,
  backwardsCompatibleFormat,
  enumUnionJsonFormat,
  JsonFormat,
  objectJsonFormat,
  stringJsonFormat,
  typedStringJsonFormat,
} from "@redotech/json/format";

import { Attachment } from "../createconversationbody";

export enum ActionType {
  SetStatus = "set_status",
  AddTag = "add_tag",
  AutoReply = "auto_reply",
  Assign = "assign",
}

export enum SetStatusActionStatus {
  OPEN = "open",
  CLOSED = "closed",
  ARCHIVED = "archived",
  SPAM = "spam",
  SNOOZED = "snoozed",
}
export enum SetStatusSnoozePeriod {
  TODAY = "today",
  TOMORROW = "tomorrow",
  WEEKEND = "weekend",
  NEXT_WEEK = "nextWeek",
}

export interface SetStatus {
  type: ActionType.SetStatus;
  status: SetStatusActionStatus;
  snoozePeriod?: SetStatusSnoozePeriod;
}

export const setStatusJsonFormat: JsonFormat<SetStatus> = objectJsonFormat(
  {
    type: typedStringJsonFormat(ActionType.SetStatus),
    status: stringJsonFormat as JsonFormat<SetStatusActionStatus>,
  },
  {
    snoozePeriod: stringJsonFormat as JsonFormat<SetStatusSnoozePeriod>,
  },
);

export interface AddTag {
  type: ActionType.AddTag;
  tag: string[] | string;
}

const tagContentFormat = backwardsCompatibleFormat({
  oldFormat: stringJsonFormat,
  newFormat: arrayJsonFormat(stringJsonFormat),
  upgrade: (oldValue: string) => [oldValue],
});

export const addTagJsonFormat: JsonFormat<AddTag> = objectJsonFormat(
  {
    type: typedStringJsonFormat(ActionType.AddTag),
    tag: tagContentFormat,
  },
  {},
);

interface AssignUser {
  id: string;
  name: string;
}

export interface Assign {
  type: ActionType.Assign;
  user: AssignUser;
}

const assignUserJsonFormat: JsonFormat<AssignUser> = objectJsonFormat(
  {
    id: stringJsonFormat,
    name: stringJsonFormat,
  },
  {},
);

export const assignJsonFormat: JsonFormat<Assign> = objectJsonFormat(
  {
    type: typedStringJsonFormat(ActionType.Assign),
    user: assignUserJsonFormat,
  },
  {},
);

export interface AutoReply {
  type: ActionType.AutoReply;
  textContent: string;
  htmlContent: string;
  attachments?: Attachment[];
}

const attachmentJsonFormat: JsonFormat<Attachment> = objectJsonFormat(
  {
    url: stringJsonFormat,
    description: stringJsonFormat,
    mimeType: stringJsonFormat,
  },
  {},
);

export const autoReplyJsonFormat: JsonFormat<AutoReply> = objectJsonFormat(
  {
    type: typedStringJsonFormat(ActionType.AutoReply),
    textContent: stringJsonFormat,
    htmlContent: stringJsonFormat,
  },
  {
    attachments: arrayJsonFormat(attachmentJsonFormat),
  },
);

export type Action = AutoReply | SetStatus | AddTag | Assign;

export const actionJsonFormat: JsonFormat<Action> = enumUnionJsonFormat(
  "type",
  "type",
  {
    [ActionType.SetStatus]: setStatusJsonFormat,
    [ActionType.AddTag]: addTagJsonFormat,
    [ActionType.AutoReply]: autoReplyJsonFormat,
    [ActionType.Assign]: assignJsonFormat,
  },
);
