import { useLoad } from "@redotech/react-util/load";
import { memo, useEffect } from "react";
import {
  Path,
  RelativeRoutingType,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { getRedirectMapping } from "../api";

export interface RedirectProps {
  to: string;
  replace?: boolean;
  relative?: RelativeRoutingType;
  preserveQuery?: boolean;
}

// Similar to react-router's Redirect component, but it can preserve the query params
export const Redirect = memo(function Redirect({
  preserveQuery,
  to,
  replace,
  relative,
}: RedirectProps) {
  const [params] = useSearchParams();
  const navigate = useNavigate();
  useEffect(() => {
    const path: Partial<Path> = {
      pathname: to,
      search: preserveQuery ? params.toString() : undefined,
    };

    navigate(path, { replace, relative });
  }, []);
  return null;
});

export const InternalRedirect = memo(function InternalRedirect() {
  const { key } = useParams();
  const redirectUrlLoad = useLoad(() => getRedirectMapping(key), []);
  const navigate = useNavigate();
  useEffect(() => {
    if (redirectUrlLoad.value) {
      navigate(redirectUrlLoad.value.redirectMapping);
    }
  }, [redirectUrlLoad]);
  return null;
});
